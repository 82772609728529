import React from "react"
import SiteLayout from "../components/sitelayout"
import SEO from "../components/seo"
import Container from "../components/container"
import ErrorState from "../components/errorstate"
import HelpLink from "../components/helplink"

import { useTheme } from "../services/theme"

const NotFoundPage = () => {
  const theme = useTheme()

  return (
    <SiteLayout>
      <SEO title="404: Not found" />
      <Container>
        <ErrorState
          show={true}
          paddingTop="10%" 
          breakpoint={theme.breakpoints.tablet} 
          responsiveStyle="padding-left: 10%; padding-right: 10%;"
          title="Oops! That page doesn't exist."
        >
          <p>Something's not right. You can go back or try looking in the <HelpLink to="/help" display="inline" fontWeight="600px">Help Center</HelpLink>.</p>
        </ErrorState>
      </Container>
    </SiteLayout>
  )
}
export default NotFoundPage
